<template>
    <div>
        <div class="header-page">
            <div class="header-font">
                {{headerText}}
            </div>
            <div>
                <div v-if="isEdit">
                    <v-btn class="mr-2" color="primary create-btn" outlined @click="cancel">Cancel</v-btn>
                    <v-btn color="primary" @click="submit">Save</v-btn>
                </div>
                <div v-else>
                    <v-btn color="primary create-btn" @click="changeToEditView">Edit</v-btn>
                </div>
            </div>
        </div>
        <v-card class="card-width">
            <v-form
                ref="form"
                v-model="valid">
                <v-container>
                    <v-row v-for="data, index in studentList" :key="data.id">
                        <v-col cols="6" class="student-name-text">{{data.studentName}}</v-col>
                        <v-col cols="6">
                            <div class="btn-status-container">
                                <v-btn :disabled="!isEdit" @click="changeStatus(index, 'ABS')" color="error" :outlined="data.status !== 'ABS'">ABSENT</v-btn>
                                <v-btn :disabled="!isEdit" @click="changeStatus(index, 'PEN')" color="warning" :outlined="data.status !== 'PEN'">PENDING</v-btn>
                                <v-btn :disabled="!isEdit" @click="changeStatus(index, 'PRS')" color="success" :outlined="data.status !== 'PRS'">PRESENT</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'UpdateStudentAttendance',
    data () {
        return {
            isEdit: false,
            valid: false,
            headerText: '',
            studentList: []
        }
    },

    methods: {
        changeStatus (index, status) {
            this.studentList[index].status = status
        },

        changeToEditView () {
            this.isEdit = true
        },

        formatDate (date) {
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },

        async getClassInfo () {
            const res = await this.axios.get(`ep-classes/${this.classId}`)
            if (res && res.data && res.data.status === 'ok') {
                const data = res.data.data
                this.headerText = `${data.name} [${this.formatDate(data.date)}] ${data.startTime} - ${data.endTime}`
            }
        },

        async submit () {
            const res = await this.axios.put(`/ep-classes/${this.classId}/student-booking-list/status`, { studentList: this.studentList })
            if (res && res.data && res.data.status === 'ok') {
                this.isEdit = false
                await this.getStudentListStatus()
            }
        },

        async getStudentListStatus () {
            const res = await this.axios.get(`/ep-classes/${this.classId}/student-booking-list/status`)
            if (res && res.data && res.data.status === 'ok') {
                this.studentList = res.data.data
            }
        },

        async cancel () {
            this.isEdit = false
            await this.getStudentListStatus()
        }
    },

    async mounted () {
        this.classId = this.$route.params.id
        await this.getStudentListStatus()
        await this.getClassInfo()
    }
}
</script>

<style lang="scss" scoped>
.btn-status-container {
    display: flex;
}
.student-name-text {
    display: flex;
    align-items: center;
    font-size: 18px;
}
</style>
